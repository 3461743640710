<template>
    <div>
        <template v-for="menu in menus">
            <el-menu-item
                    v-if="menu.uri !=''"
                    :index="menu.uri"
                    :key="menu.id"
                    @click="selectMenu(menu.uri)"
            >{{menu.title}}
            </el-menu-item>

            <el-submenu :key="menu.id" :index="menu.id+''" v-if="menu.uri =='' && menu.children">
                <template slot="title">{{menu.title}}</template>
                <menuTree :menus="menu.children"></menuTree>
            </el-submenu>
        </template>
    </div>
</template>
<script>
    export default {
        name: "menuTree",
        props: {
            menus: {
                type: Array
            }
        },
        methods: {
            selectMenu(path) {
                window.sessionStorage.setItem("menuPath", path);
            }
        }
    };
</script>
<style scoped>
    .el-menu-item.is-active {
        background-color: #F4F4F5 !important;
    }

    .el-menu-item:hover {
        background-color: #F4F4F5 !important;
    }

    .el-menu-item {
        line-height: 46px;
        height: 46px
    }
</style>>
