import request from '@/request/request'

export function getUserAll(data) {
    return request({
        url: '/api/users/all',
        method: 'GET',
        data
    })
}

export function getSaleUser() {
    return request({
        url: '/api/users/sale',
        method: 'GET',
    })
}

export function getMenu() {
    return request({
        url: '/api/users/menu',
        method: 'GET',
    })
}

export function get(params) {
    return request({
        url: '/api/users',
        method: 'GET',
        params
    })
}

export function edit(id) {
    return request({
        url: '/api/users/' + id + '/edit',
        method: 'GET',
    })
}

export function update(id, data) {
    return request({
        url: '/api/users/' + id,
        method: 'PUT',
        data
    })
}

export function store(data) {
    return request({
        url: '/api/users',
        method: 'POST',
        data
    })
}

export function getType() {
    return request({
        url: '/api/users/type',
        method: 'GET'
    })
}

export function updatePassword(password, password_confirmation) {
    return request({
        url: '/api/users/password',
        method: 'PATCH',
        data: {
            'password': password,
            'password_confirmation': password_confirmation
        }
    })
}

export function profile() {
    return request({
        url: '/api/profile',
        method: 'GET'
    })
}

export function getConfig() {
    return request({
        url: '/api/users/config',
        method: 'GET'
    })
}
