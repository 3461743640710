import Vue from 'vue'
import App from './App.vue'
import router from "./router/router"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import buttonPermission from './components/button/buttonPermission'
import {check as buttonCheck} from "@/request/api/permission"
import store from './store'
import './icons' // icon
import {accAdd, accSub, accMul, accDiv} from '@/assets/math'

Vue.config.productionTip = false
Vue.use(ElementUI, {size: 'mini'});
Vue.prototype.buttonCheck = buttonCheck
Vue.prototype.accAdd = accAdd // +
Vue.prototype.accSub = accSub // -
Vue.prototype.accMul = accMul // *
Vue.prototype.accDiv = accDiv // /

Vue.component('button-permission', buttonPermission)
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
