import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/view/Home"

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: "/",
        redirect: '/home'
    },
    {
        path: "/Login",
        name: 'Login',
        component: () => import('@/view/Login')
    },
    {
        path: "/",
        name: 'home',
        meta: { title: '首页' },
        component: Home,
        children: [
            {
                path: '/redirect/:path*',
                component: () => import('@/view/redirect/index')
            },
            {
                path: "/home",
                name: 'home',
                meta: { title: '首页' },
                component: () => import('@/view/dashboard/index')
            },
            {
                path: "/drainage",
                name: 'drainage',
                meta: { title: '引流列表' },
                component: () => import('@/view/drainage/DrainageList')
            },
            {
                path: "/company",
                name: 'company',
                meta: { title: '公司列表' },
                component: () => import('@/view/company/CompanyList')
            },
            {
                path: "/user",
                name: 'user',
                meta: { title: '用户列表' },
                component: () => import('@/view/user/index'),
            },
            {
                meta: { title: '用户创建' },
                path: "/user/create",
                name: 'userCreate',
                component: () => import('@/view/user/create')
            },
            {
                meta: { title: '用户编辑' },
                name: 'userEdit',
                path: "/user/edit/:id",
                component: () => import('@/view/user/edit')
            },
            {
                meta: { title: '修改密码' },
                path: "/user/password",
                component: () => import('@/view/user/password')
            },
            {
                path: "/project",
                name: 'project',
                meta: { title: '项目列表' },
                component: () => import('@/view/project/ProjectList')
            },
            {
                path: "/deplist",
                name: 'depList',
                meta: { title: '部门列表' },
                component: () => import('@/view/department/DepList')
            },
            {
                path: "/user-wechat",
                name: 'wechartList',
                meta: { title: "微信管理" },
                component: () => import('@/view/wechart/WechartList')
            },
            {
                path: "/Permission",
                name: 'Permission',
                meta: { title: "权限管理" },
                component: () => import('@/view/permission/index')
            },
            {
                path: "/RoleList",
                name: 'RoleList',
                meta: { title: '角色列表' },
                component: () => import('@/view/role/RoleList')
            },
            {
                path: "/Menu",
                name: "Menu",
                meta: { title: '菜单列表' },
                component: () => import('@/view/menu/index')
            },
            {
                path: "/customer",
                name: "customer",
                meta: { title: '客户列表' },
                component: () => import('@/view/customer/CustomerList')
            },
            {
                path: "/test",
                name: "test",
                component: () => import('@/view/Test')
            },
            {
                path: "/customer-level",
                name: "customer-level",
                meta: { title: '客户等级' },
                component: () => import('@/view/customer/CustomerLevel')
            },
            {
                path: "/customer-active",
                name: "customer-active",
                meta: { title: "客户活跃" },
                component: () => import('@/view/customer/CustomerActive')
            },
            {
                path: "/customer-type",
                name: "customer-type",
                meta: { title: "客户类型" },
                component: () => import('@/view/customer/CustomerType')
            }, {
                path: "/capital-account",
                name: "capital-account",
                meta: { title: "资金账户" },
                component: () => import('@/view/capital_account/index')
            }, {
                path: "/capital-account/show/:id",
                name: "capital-account/show",
                meta: { title: "账户详情" },
                component: () => import('@/view/capital_account/show')
            },
            {
                path: "/promote",
                name: "promote",
                component: () => import('@/view/promote/PromoteList')
            },
            {
                path: "/promote-user-add",
                name: "promote-user-add",
                meta: { title: "加人管理" },
                component: () => import('@/view/promote/PromoteUserAdd')
            },
            {
                path: "/promote-user-add-info",
                name: "promote-user-add-info",
                meta: { title: "加人" },
                component: () => import('@/components/promote/PromoteUserAddInfo')
            },
            {
                path: "/collecting-account",
                name: "collecting-account",
                meta: { title: "收款账户" },
                component: () => import('@/view/account/CollectingList')
            },
            {
                path: "/withdrawal-account",
                name: "withdrawal-account",
                component: () => import('@/view/account/WithdrawalList')
            },
            {
                path: "/courier",
                name: "courier",
                meta: { title: "快递管理" },
                component: () => import('@/view/account/CourierList')
            },
            {
                path: "/medical-record-form/:id",
                name: "medical-record-form",
                meta: { title: '添加病历', noCache: true },
                component: () => import('@/components/customer/medicalrecordForm')
            },
            {
                path: "/after-visit/:id",
                name: "after-visit",
                meta: { title: '追访列表', noCache: true },
                component: () => import('@/view/customer/after-visit-list')
            },
            {
                path: "/medical-record-form-break/:id/:send_day",
                name: "medical-record-form-break",
                meta: { title: '添加病历', noCache: true },
                component: () => import('@/view/medical/medical-record-form-break')
            },
            {
                path: "/vocher",
                name: "vocher",
                meta: { 'title': '订单列表', noCache: true },
                component: () => import('@/view/vocher/index')
            },
            {
                path: "/order",
                name: "order",
                meta: { 'title': '订单列表', noCache: true },
                component: () => import('@/view/order/index')
            },
            {
                path: "/before-sale",
                name: "before-order",
                meta: { 'title': '订单列表'},
                component: () => import('@/view/order/before-sale')
            },
            {
                path: "/order-wait-pass",
                name: "order-wait-pass",
                meta: { 'title': '待审核订单', noCache: true },
                component: () => import('@/view/order/order-wait-pass')
            }, {
                path: "/order-wait-full-pass",
                name: "order-wait-full-pass",
                meta: { 'title': '待改全款订单', noCache: true },
                component: () => import('@/view/order/order-wait-full-pass')
            }, {
                path: "/order-wait-deliver",
                name: "order-wait-deliver",
                meta: { 'title': '待发货订单', noCache: true },
                component: () => import('@/view/order/order-wait-deliver')
            }, {
                path: "/order-wait-cancel",
                name: "order-wait-cancel",
                meta: { 'title': '待取消订单', noCache: true },
                component: () => import('@/view/order/order-wait-cancel')
            }, {
                path: "/order-no-shipping",
                name: "order-no-shipping",
                meta: { 'title': '暂不发货订单', noCache: true },
                component: () => import('@/view/order/order-no-shipping')
            }, {
                path: "/order-break",
                name: "order-break",
                meta: { 'title': '分发订单', noCache: true },
                component: () => import('@/view/order/order-break')
            },{
                path: "/show-history-order/:id",
                name: "show-history-order",
                meta: { 'title': '订单', noCache: true },
                component: () => import('@/view/order/history-order')
            },{
                path: "/order-split/:id",
                name: "order-split",
                meta: { 'title': '订单拆分', noCache: true },
                component: () => import('@/view/order/order-split')
            },{
                path: "/order-edit-sale/:id",
                name: "order-edit-sale",
                meta: { 'title': '改销售', noCache: true },
                component: () => import('@/view/order/order-edit-sale')
            }, {
                path: "/order-edit-price/:id",
                name: "order-edit-price",
                meta: { 'title': '订单改价', noCache: true },
                component: () => import('@/view/order/order-edit-price')
            }, {
                path: "/order-edit-price-list",
                name: "order-edit-price-list",
                meta: { 'title': '订单改价', noCache: true },
                component: () => import('@/view/order/order-edit-price-list')
            }, {
                path: "/order-replacement/:id",
                name: "order-replacement",
                meta: { 'title': '订单补发', noCache: true },
                component: () => import('@/view/order/replacement')
            }, {
                path: "/order-replace-pass",
                name: "order-replace-pass",
                meta: { 'title': '订单补发审核', noCache: true },
                component: () => import('@/view/order/order-replace-pass')
            },
            {
                path: "/account-order",
                name: "account-order",
                meta: { 'title': '待提现订单' },
                component: () => import('@/view/account_order/index')
            },
            {
                path: "/order-edit/:id(\\d+)",
                name: "orderEdit",
                meta: { title: '订单编辑', noCache: true },
                component: () => import('@/view/order/orderEdit')
            },
            {
                path: "/order-dha-edit/:id(\\d+)",
                name: "orderDhaEdit",
                meta: { title: '订单编辑', noCache: true },
                component: () => import('@/view/order/orderDhaEdit')
            },
            {
                path: "/receipt",
                name: "receipt",
                meta: { title: '收款单' },
                component: () => import('@/view/receipt/receipt')
            },
            {

                path: "/order-wait",
                name: "orderWait",
                meta: { title: '待收' },
                component: () => import('@/view/order/orderWait')
            }, {
                path: "/receipt-index",
                name: "receipt-index",
                meta: { title: "收款单列表" },
                component: () => import('@/view/receipt/index')
            },
            {
                path: "/receipt/show/:id",
                name: "receipt-show",
                meta: { title: "收款单详情" },
                component: () => import('@/view/receipt/show')
            },
            {
                path: "/other-receipt",
                name: "other-receipt",
                meta: { title: "其他收款单" },
                component: () => import('@/view/other_receipt/index')
            },
            {
                path: "/other-receipt-add",
                name: "other-receipt-add",
                meta: { title: "添加其他收款单" },
                component: () => import('@/view/other_receipt/add')
            },
            {
                path: "/purchase-order",
                name: "purchase-order",
                meta: { title: "采购单列表" },
                component: () => import('@/view/purchase_order/index')
            },
            {
                path: "/purchase-order/create",
                name: "purchase-order-create",
                meta: { title: "创建采购单" },
                component: () => import('@/view/purchase_order/add')
            },
            {
                path: "/other-payment-bill",
                name: "other-payment-bill",
                meta: { title: "其他付款单" },
                component: () => import('@/view/other_payment_bill/index')
            },
            {
                path: "/other-payment-bill/create",
                name: "other-payment-bill-create",
                meta: { title: "添加其他付款单" },
                component: () => import('@/view/other_payment_bill/add')
            },
            {
                path: "/prescription/:id?",
                name: "prescription",
                meta: { title: "病历列表", noCache: true },
                component: () => import('@/view/customer/prescription')
            },
            {
                path: "/prescription-wait",
                name: "prescription-wait",
                meta: { title: "病历未处理", noCache: true },
                component: () => import('@/view/customer/prescription-wait')
            },
            {
                path: "/medical-solve/:id",
                name: "medical-solve",
                meta: { title: "订单开方", noCache: true },
                component: () => import('@/components/customer/medicalSolve')
            },
            {
                path: "/prescription-detail/:id",
                name: "prescription-detail",
                meta: { title: "病例详情", noCache: true },
                component: () => import('@/components/customer/prescriptionDetail')
            },
            {
                path: "/prescription-edit/:id",
                name: "prescription-edit",
                meta: { title: "病例编辑", noCache: true },
                component: () => import('@/components/customer/prescriptionEdit')
            },
            {
                path: "/refund/:id",
                name: "refund",
                meta: { title: "申请售后" },
                component: () => import('@/view/refund/refund')
            },
            {
                path: "/promote-account",
                name: "promote-account",
                meta: { title: "账户管理" },
                component: () => import('@/view/promote/PromoteAccount')
            },
            {
                path: "/promote-account/show/:id",
                name: "promote-account/show",
                meta: { title: "账户明细" },
                component: () => import('@/view/promote/account/show')
            },
            {
                path: "/promote-account-add/:id",
                name: "promote-account-add",
                meta: { title: "账户信息添加" },
                component: () => import('@/components/promote/PromoteAccountAdd')
            },
            {
                path: "/promote-account-recharge/index",
                name: "promote-account-recharge/index",
                meta: { title: "充值提现" },
                component: () => import('@/view/promote/account_recharge/index')
            },
            {
                path: "/promote/promote-info",
                name: "promote-info",
                meta: { title: "推广数据" },
                component: () => import('@/view/promote/info')
            },
            {
                path: "/promote-user-data",
                name: "promote-user-data",
                meta: { title: "人员数据统计" },
                component: () => import('@/view/promote/userData')
            },
            {
                path: "/before-count",
                name: "before-count",
                meta: { title: "售前的复购统计" },
                component: () => import('@/view/order/before-sale-data')
            },
            {
                path: "/promote-wechat-data",
                name: "promote-wechat-data",
                meta: { title: "微信数据统计" },
                component: () => import('@/view/promote/wechatData')
            },
            {
                path: "/promote-project-data",
                name: "promote-project-data",
                meta: { title: "项目数据统计" },
                component: () => import('@/view/promote/projectData')
            },
            {
                path: "/order-data",
                name: "orderData",
                meta: { title: "数据统计" },
                component: () => import('@/view/order/orderData')
            },
            {
                path: "/service-order",
                name: "service-order",
                meta: { title: "服务单列表" },
                component: () => import('@/view/service_order/index')
            },
            {
                path: "/promote-info-sum",
                name: "promote-info-sum",
                meta: { title: "推广信息数据汇总" },
                component: () => import('@/view/promote/statistic/promoteInfoSum')
            },
            {
                path: "/category",
                name: "category",
                meta: { title: "分类管理" },
                component: () => import('@/view/product/category')
            },
            {
                path: "/brand",
                name: "brand",
                meta: { title: "品牌管理" },
                component: () => import('@/view/product/brand')
            },
            {
                path: "/product",
                name: "product",
                meta: { title: "商品管理" },
                component: () => import('@/view/product/product/index')
            },
            {
                path: "/product/create",
                name: "product/create",
                meta: { title: "商品添加" },
                component: () => import('@/view/product/product/create')
            },
            {
                path: "/product/:id",
                name: "product-show",
                meta: { title: "商品详情" },
                component: () => import('@/view/product/product/show')
            },
            {
                path: "/product/edit/:id",
                name: "product/edit",
                meta: { title: "商品修改" },
                component: () => import('@/view/product/product/edit')
            },
            {
                path: "/product-specs",
                name: "product-specs",
                meta: { title: "商品规格库存预警" },
                component: () => import('@/view/product/product_spec/index')
            },
            {
                path: "/add-order-form/:id",
                name: "addOrderForm",
                meta: { title: "填单" },
                component: () => import('@/view/customer/addOrderForm')
            },
            {
                path: "/delivery-order",
                name: "deliveryOrderIndex",
                meta: { title: "发货单列表" },
                component: () => import('@/view/delivery_order/index')
            },
            {
                path: "/delivery-orders",
                name: "deliveryOrdersIndex",
                meta: { title: "发货状态" },
                component: () => import('@/view/delivery_orders/index')
            },
            {
                path: "/delivery-order/create/:order_id",
                name: "deliveryOrderCreate",
                meta: { title: "生成发货单" },
                component: () => import('@/view/delivery_order/create')
            },
            {
                path: "/delivery-order/:id(\\d+)",
                name: "deliveryOrderShow",
                meta: { title: "发货单详情" },
                component: () => import('@/view/delivery_order/show')
            },
            {
                path: "/delivery-order/print",
                name: "deliveryOrderPrint",
                meta: { title: "打印发货单" },
                component: () => import('@/view/delivery_order/print')
            },
            {
                path: "/delivery-order/printer-setting",
                name: "deliveryOrderPrinterSetting",
                meta: { title: "配置打印机" },
                component: () => import('@/view/delivery_order/printerSetting')
            },
            {
                path: "/put-stack/create/:id(\\d+)",
                name: "putStockCreate",
                meta: { title: "入库" },
                component: () => import('@/view/product/putStack/create')
            },
            {
                path: "/project-promote-info",
                name: "projectPromoteInfo",
                meta: { title: "推广信息项目统计" },
                component: () => import('@/view/promote/statistic/projectPromoteInfo')
            },
            {
                path: "/basic_salary",
                name: "basicSalary",
                meta: { title: "工资底薪" },
                component: () => import('@/view/basic_salary/index')
            },
            {
                path: "/basic_salary/create",
                name: "basicSalaryCreate",
                meta: { title: "创建底薪" },
                component: () => import('@/view/basic_salary/create')
            },
            {
                path: "/basic_salary/:id/edit",
                name: "basicSalaryEdit",
                meta: { title: "修改底薪" },
                component: () => import('@/view/basic_salary/edit')
            },
            {
                path: "/reward_salary",
                name: "rewardSalary",
                meta: { title: "工资奖励" },
                component: () => import('@/view/reward_salary/index')
            },
            {
                path: "/reward_salary/create",
                    name: "rewardSalaryCreate",
                meta: { title: "创建奖励" },
                component: () => import('@/view/reward_salary/create')
            },
            {
                path: "/reward_salary/:id/edit",
                    name: "rewardSalaryEdit",
                meta: { title: "修改奖励" },
                component: () => import('@/view/reward_salary/edit')
            },
            {
                path: "/salary_commission",
                name: "salaryCommission",
                meta: { title: "工资提成" },
                component: () => import('@/view/salary_commission/index')
            },
            {
                path: "/salary_commission/create",
                name: "salaryCommissionCreate",
                meta: { title: "创建提成" },
                component: () => import('@/view/salary_commission/create')
            },
            {
                path: "/salary_commission/:id/edit",
                name: "salaryCommissionEdit",
                meta: { title: "修改提成" },
                component: () => import('@/view/salary_commission/edit')
            },
            {
                path: "/delivery_address",
                name: "deliveryAddress",
                meta: { title: "发货地址列表" },
                component: () => import('@/view/delivery_address/index')
            },
            {
                path: "/salary",
                name: "salary",
                meta: { title: "工资单" },
                component: () => import('@/view/salary/index')
            },
            {
                path: "/salary/creats",
                name: "salaryCreats",
                meta: { title: "生成工资单" },
                component: () => import('@/view/salary/creats')
            },
            {
                path: "/actual-user-add/index",
                name: "actualUserAddIndex",
                meta: { title: "微信加人核对" },
                component: () => import('@/view/actual_user_add/index')
            },
            {
                path: "/actual-user-add/create",
                name: "actualUserAddCreate",
                meta: { title: "微信加人核对提交" },
                component: () => import('@/view/actual_user_add/create')
            },
        ]
    },
    {
        path: "*",
        component: () => import('@/view/error_page/404')
    }
]
let router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login') {
        const time = parseInt(new Date().getTime() / 1000)
        //window.localStorage.getItem('refresh_expires_in')
        if (window.localStorage.getItem('expires_in') <= time) {
            window.localStorage.clear()
        }

        if (!window.localStorage.getItem('access_token')) {
            next({
                path: '/Login'
            })
        }

        next()
    } else {
        next()
    }
})
export default router;
