<template>
    <div>
        <el-button v-if="isHavePermission" :type="type" :icon="icon" :size="size">{{name}}</el-button>
    </div>
</template>
<script>
    import {check as buttonCheck} from "@/request/api/permission";
    export default {
        name: "buttonPermission",
        props: {
            slug: {
                type: String,
            },
            name: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'primary'
            },
            icon: {
                type: String,
                default: ''
            },
            size: {
                type: String,
                default: 'small'
            }
        },
        data() {
            return {
                isHavePermission: false
            }
        },
        created() {
            this.buttonCheck()
        },
        methods: {
            buttonCheck() {
                buttonCheck({slug: this.slug}).then(res => {
                    this.isHavePermission = res.data
                })
            }
        }
    };
</script>
<style scoped>
</style>>
