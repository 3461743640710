import axios from 'axios'
import {Message} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const service = axios.create({
	//  baseURL: "http://www.aged.com",
	timeout: 150000 // request timeout
})

service.interceptors.request.use(
    config => {
        NProgress.start()
        config.headers['Authorization'] = window.localStorage.getItem('token_type') + ' ' + window.localStorage.getItem('access_token')
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        NProgress.done()
        const res = response.data
        if (res.code != 200 && res.code != 201) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })

            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                console.log(12213)
            }

        }

        return Promise.resolve(res);
    },
    error => {
        NProgress.done()

        if (error.response.data.code === 422 && error.response.data.errors) {
            const errors = error.response.data.errors
            for (let keys in errors) {
                setTimeout(function () {
                    Message({
                        showClose: true,
                        type: 'error',
                        message: errors[keys]
                    });
                }, 100, {
                    errors,
                    keys
                })
            }
        } else {
            Message({
                showClose: true,
                type: 'error',
                message: error.response.data.message
            });
        }

        return Promise.reject(error.response.data)
    }
)

export default service
