import request from '@/request/request'

export function get(params) {
    return request({
        url: '/api/permissions',
        method: 'GET',
        params
    })
}

export function getPermissionAll() {
    return request({
        url: '/api/permissions/all',
        method: 'GET'
    })
}

export function edit(id) {
    return request({
        url: '/api/permissions/' + id + '/edit',
        method: 'GET'
    })
}

export function store(data) {
    return request({
        url: '/api/permissions',
        method: 'POST',
        data
    })
}

export function update(id, data) {
    return request({
        url: '/api/permissions/' + id,
        method: 'PUT',
        data
    })
}

export function destroy(id) {
    return request({
        url: '/api/permissions/' + id,
        method: 'DELETE'
    })
}

export function check(params) {
    return request({
        url: '/api/permissions/check',
        method: 'GET',
        params
    })
}

