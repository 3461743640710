<template>
    <el-container class="container">
        <!--header-->
        <el-header style="position:relative">
            <el-menu
                class="el-menu-top"
                mode="horizontal"
                background-color="#3c8dbc"
                text-color="#fff"
                active-text-color="#fff"
            >
                <el-menu-item index="xx" :width="isCollapse?'24px':'205px'" style="width: 205px;text-align:center">
                  <el-link :underline="false" href="/home">旺宸 OA 系统</el-link>
                </el-menu-item>

                <el-menu-item index="icon" @click="toggleMenu()"> <i class="el-icon-s-fold" style='color:#fff'></i></el-menu-item>

                <el-submenu index="sss" style="float: right">
                    <template slot="title">
                      <el-avatar :src="profile.avatar"></el-avatar>
                      <span style="margin-left: 10px">{{profile.name}}</span>
                    </template>
                    <el-menu-item @click="updatePassword">修改密码</el-menu-item>
                    <el-menu-item index="sss-1" @click="clearCache">清除缓存</el-menu-item>
                    <el-menu-item @click="loginOut" index="sss-2">退出登录</el-menu-item>
                </el-submenu>
            </el-menu>
        </el-header>
        <!--底部-->
        <el-container style="overflow:hidden">
            <!-- <el-aside :width="isCollapse?'64px':'205px'" style='border-right:5px solid #f5f5f5; position:absolute; top:60px; left:0; bottom:0'> -->
            <el-aside
                :width="isCollapse?'24px':'205px'"
                style="float:left; border-right:5px solid #f5f5f5"
            >
                <el-menu
                    class="el-menu-vertical-demo"
                    background-color="#F9FAFC"
                    text-color="#000"
                    active-text-color="black"
                    router
                    :default-active="menuPath"
                    :collapse="isCollapse"
                    :collapse-transition="false"
                >
                    <menuTree :menus="leftMenuList"></menuTree>
                </el-menu>
            </el-aside>
            <!-- <el-container style="position:absolute; left:210px; top:60px; bottom:0; right:0"> -->
            <el-container style="float:left">
                <tags-view/>
                <el-main>
                    <keep-alive :include="cachedViews">
                        <router-view :key="key"/>
                    </keep-alive>
                </el-main>
                <el-footer height="20px">
                    <!--                    <el-button type="text">文字按钮</el-button>-->
                    <el-link type="info">Copyright©2020 山东旺宸生物科技有限公司 ICP证：鲁ICP备20027478号-1</el-link>
                </el-footer>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
import menuTree from "@/components/common/menuTree";
import { getMenu, profile } from "@/request/api/users";
import { logout } from "@/request/api/auth";
import TagsView from "@/layout/components/TagsView";
import avatar from "@/assets/avatar.gif"

export default {
  name: "Home",
  components: {
    menuTree,
    TagsView
  },
  data() {
    return {
      list: [],
      leftMenuList: [],
      topMenuIndex: "",
      menuPath: "",
      isCollapse: false,
      profile: {
        avatar: avatar
      }
    };
  },
  created() {
    this.getCategoryList();
    profile().then(res => {
      this.$store.profile = res.data
      this.profile = res.data
      this.profile.avatar = res.data.avatar ? res.data.avatar : avatar
    })
  },
  methods: {
    toggleMenu() {
      this.isCollapse = !this.isCollapse;
    },
    clearCache() {
      this.$message.success("清除成功~");
    },
    topSelect(index) {
      window.sessionStorage.setItem("topMenuId", this.list[index].id);
      window.sessionStorage.setItem("topMenuIndex", index);
      this.leftMenuList = this.list[index].children;
      if (this.list[index].uri) {
        this.$router.push(this.list[index].uri);
        return;
      }
    },
    loginOut() {
      logout()
        .then(response => {
          window.localStorage.clear();
          this.$message({
            showClose: true,
            message: response.message,
            type: "success"
          });
          this.$router.push("/Login");
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: error.message,
            type: "error"
          });
        });
    },
    getCategoryList() {
      getMenu().then(response => {
        this.list = response.data;
        // let topMenuId = window.sessionStorage.getItem("topMenuId");
        // let topMenuIndex = window.sessionStorage.getItem("topMenuIndex");
        this.menuPath = window.sessionStorage.getItem("menuPath");
        this.leftMenuList = response.data;
        // if (topMenuId != null) {
        //   this.topMenuIndex = topMenuId;
        //   if (response.data[topMenuIndex].uri == "") {
        //     this.topSelect(topMenuIndex);
        //   }
        // }
      });
    },
    updatePassword() {
      this.$router.push({ path: "/user/password" });
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  height: 100%;
}

.el-header {
  background: #314155;
  padding: 0;
}

.el-aside {
  background: #F9FAFC;
}

.el-main {
  background: transparent;
}

.el-footer {
  border-top: 1px solid #e6e6e6;
  text-align: center;
  line-height: 25px;
}

</style>
